<template>
    <div class="card card-custom gutter-b card-stretch">
        <div class="card-body pt-4">
            <div class="d-flex justify-content-end">
                <div class="dropdown dropdown-inline show" data-toggle="tooltip" title="" data-placement="left" data-original-title="Quick actions">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <span class="svg-icon  svg-icon-primary svg-icon-md">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1">
                                        <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                        <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                    </g>
                                </svg>
                            </span>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="{ action: 'edit', data: item }" ><i class="pi pi-pencil text-primary"></i> Edit Teacher </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'credentials', data: item }" ><i class="pi pi-key text-primary"></i> Email Credentials </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'delete', data: item }"><i class="pi pi-trash text-primary"></i>  Delete Teacher</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
            <div class="d-flex align-items-center mb-7 mt-0">
                <div class="flex-shrink-0 mr-4">
                    <div class="symbol symbol-circle symbol-lg-75">
                        <img v-if="item.user.thumbnail_type == 'image'
                                    || item.user.thumbnail_type == 'upload' 
                                    || item.user.thumbnail_type == 'library'"  :src="`${item.user.path}${item.user.thumbnail}`" alt="image">
                        <div  v-if="item.user.thumbnail_type == 'color'" :style="{ 'background-color': `#${item.user.thumbnail_bg}`, height: '50px', width: '50px', 'border-radius': '50%'}"></div>
                    </div>
                </div>
                <div class="d-flex flex-column">
                    <a href="#" class="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">{{ item.name }}</a>
                    <span class="text-muted font-weight-bold">{{ item.email }}</span>
                </div>
            </div>
            <p class="mb-7">{{ item.title }}</p>
            <div class="mb-0">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="text-dark-75 font-weight-bolder mr-2"># of students:</span>
                    <a href="#" class="text-muted text-hover-primary">{{ item.studentcount}}</a>
                </div>
            </div>
             <div class="mb-0">
            <p class="text-dark-75 font-weight-bolder mr-2">Class</p>
            <span v-if="item.classlist.length > 0">
                <span  v-for="(row, t) in item.classlist" :key="t"  class="label label-lg font-weight-bold mb-2 label-light-primary label-inline mr-1 ml-1">{{ row.name }}</span>
            </span>
        </div>
        </div>
        <div class="card-footer d-flex align-items-sm-center flex-wrap flex-column flex-sm-row" style="padding: 1rem 2.25rem">
            <div class="d-flex">
                <div class="d-flex mr-7">
                    <p class="text-muted pt-2" >{{ moment(item.created_at).format('LL') }}</p>
                </div>
            </div>
            <button type="button"  @click="handleCommand({ action: 'edit', data: item })"  class="btn btn-light btn-sm mt-sm-0 mr-auto mr-sm-0 ml-sm-auto">Edit Teacher</button>
        </div>
    </div> 
</template>
<script>
import { defineComponent, inject, computed } from 'vue'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import moment from 'moment'

export default defineComponent({
    components: { ElDropdown, ElDropdownItem, ElDropdownMenu },
    props: {
        item: {
            default: []
        },
        index: {
            default: 0,
            type: Number
        }
    },
    setup() {
        const { constants, store } = inject('App')
        const { handleCommand } = inject('Teachers')

        return {
            handleCommand,
            user: computed(() => store.getters['UserModule/GET_USER']), moment
        }
    },
})
</script>
