
import { reactive, ref } from 'vue'
export default function TeachersAdd(http, constant, toast) {
    const create_status = ref(0)
    const createResponse = reactive({
        status:  false,
        data: []
    })
    async function addTeacher(id, teacher_id, view, teachersData, thumbnail_type, thumbnail_color, teacher_thumb, thumbnail_src, isNotify, isUpdateAvatar) {
        try {
            create_status.value = 1
            createResponse.value = []

            const f = new FormData(teachersData);
            f.append('thumbnail', teacher_thumb)
            f.append('thumbnail_color', thumbnail_color)
            f.append('thumbnail_type', thumbnail_type)
            f.append('thumbnail_src', thumbnail_src)
            f.append('is_notify', (isNotify) ? 1: 0)
            f.append('is_update_avatar', (isUpdateAvatar) ? 1 : 0)
            
            if (view  == 'edit') {
                f.append('id', id)
                f.append('teacher_id', teacher_id)
            }
            
            const response = await http.post(view  == 'edit' ? constant.TEACHERS_EDIT :  constant.TEACHERS_ADD, f)
            create_status.value = 0
            if (response.data.status > 0) {
                toast.add({severity:'success', summary: 'Success', detail:'Teacher was successfully created/updated.', life: 3000});
                createResponse.data = response.data.data.teacher
                createResponse.status = true
            } else {
                create_status.value = -1
                createResponse.status = false
                toast.add({severity:'error', summary: 'Error', detail:'Unable to create to teacher, Email address already exists.', life: 3000});
            }
        } catch (err) {
            create_status.value = 0
        }
    }
    return {
        createResponse,
        create_status,
        addTeacher
    }
}