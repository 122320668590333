<template>
     <DataTable  class="p-datatable-sm" ref="dt"  :value="teacher_list"
        dataKey="id" :filters="filter.filter_list"  filterDisplay="menu"  :loading="loading" responsiveLayout="stack" breakpoint="960px"
        :globalFilterFields="['name']"
        :paginator="true" :rows="6" 
        :resizableColumns="true" columnResizeMode="fit"
         :rowsPerPageOptions="[6, 10,20,50]"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
            <template #header>
            
            </template>
            <Column field="name" header="Name" :sortable="true">
                    <template #body="slotProps">
                        <span class="d-flex">
                        <Avatar v-if="slotProps.data.user.thumbnail_type == 'image'
                                || slotProps.data.user.thumbnail_type == 'upload' 
                                || slotProps.data.user.thumbnail_type == 'library' " :image="`${slotProps.data.user.path}${slotProps.data.user.thumbnail}`" class="p-mr-2" size="xlarge" shape="circle" />
                        <div  v-if="slotProps.data.user.thumbnail_type == 'color'" :style="{ 'background-color': `#${slotProps.data.user.thumbnail_bg}`, height: '40px', width: '40px', 'border-radius': '50%'}"></div>
                        <p class="ml-5 pt-4">{{slotProps.data.name}}</p>
                        </span>
                    </template>
            </Column>
            <Column field="email" header="Email" :sortable="true"> </Column>
            <Column field="studentcount" header="# of Students" :sortable="true"></Column>
            <Column field="classlist" header="Class" :sortable="true">
                <template #body="props">
                     <span v-if="props.data.classlist.length > 0">
                        <span  v-for="(row, t) in props.data.classlist" :key="t"  class="label label-lg font-weight-bold mb-2 label-light-primary label-inline mr-1 ml-1">{{ row.name }}</span>
                    </span>
                </template>
            </Column>

           
            <Column field="created_at" header="Created At" :sortable="true">
                <template #body="props">
                     <p class="text-muted pt-2" >{{ moment(props.data.created_at).format('LL') }}</p>
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <a href="javascript:void(0);" class="mr-2" data-toggle="dropdown">
                                <span class="svg-icon  svg-icon-primary svg-icon-md">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1">
                                            <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                            <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                        </g>
                                    </svg>
                                </span>
                            </a>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item :command="{ action: 'edit', data: slotProps.data }" ><i class="pi pi-pencil text-primary"></i> Edit Teacher </el-dropdown-item>
                                <el-dropdown-item :command="{ action: 'delete', data: slotProps.data }"><i class="pi pi-trash text-primary"></i>  Delete Teacher</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </template>
            </Column>
            <template #loading>
                Loading customers data. Please wait.
            </template>
            <template #empty>
                No users found.
            </template>
    </DataTable>
</template>
<script>
import { defineComponent, inject, computed } from 'vue'
import { ElDropdown, ElDropdownItem, ElDropdownMenu }   from  'element-plus'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Avatar from 'primevue/avatar'
import moment from 'moment'

export default defineComponent({
    components: { Avatar, ElDropdown, ElDropdownItem, ElDropdownMenu, DataTable, Column },
    props: {
        teacher_list: {
            default: []
        },
        loading: {
            default: false,
            type: Boolean
        },
        filter: {
           default: []
        }
    },
    setup() {
        const { constants, store } = inject('App')
        const { handleCommand } = inject('Teachers')

        return {
            handleCommand, moment,
            user: computed(() => store.getters['UserModule/GET_USER'])
        }
    },
})
</script>
