<template>
   <Dialog :header="`Students List | ${selected_teacher.fname}`"
      :draggable="false"  :modal="true" v-model:visible="display" :maximizable="true"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '90vw'}">
        <Students :class_id="0" :teacher_id="selected_teacher.user_id"></Students>
    </Dialog>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import Students from '../students/Students.vue'
import Dialog from 'primevue/dialog';

export default defineComponent({
    components: { Students, Dialog },
    props: {
        student_list_watcher: {
            type: Number,
            default: 1
        },
        selected_teacher: {
            default: []
        },
    },
    setup(props) {
        let display = ref(false)

        watch(()=>props.student_list_watcher, (n) => {
            display.value = true
        })
        return {
            display
        }
    },
})
</script>
