<template>
<div>
<div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div class="d-flex align-items-center flex-wrap mr-1">
                <div class="d-flex align-items-baseline flex-wrap mr-5">
                    <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 pl-0 font-size-sm">
                        <li class="breadcrumb-item">
                            {{teacher_list.length}} Total
                        </li>
                    </ul>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div class="card-toolbar card-actions">
                    <a href="Javascript:void(0)" class="btn font-weight-bolder export-action text-893BF7 mr-3">
                        <i class="fas fa-file-export icon-md text-893BF7"></i>Export CSV Teachers
                    </a>
                    <a href="Javascript:void(0)" @click="addTeacher" class="btn font-weight-bolder add-action   add-button-margin">
                        <i class="fas fa-user-plus icon-md"></i>Add Teacher
                    </a>
                </div>
            </div>
    </div>
</div>
<div class="d-flex flex-column-fluid">
    <div class="container">
        <div class="card-header  p-0 pt-0 border-0 bg-none">
            <h3 class="card-title  u-flex">
                <span class="card-label fw-bolder fs-3 mb-1 u-flex-1 pt-2" id="tabView">
                    <SelectButton v-model="selected_view" :options="view_options" :dataKey="value">
                        <template #option="slotProps">
                            <i :class="slotProps.option.icon"></i>
                        </template>
                    </SelectButton>
                </span>
                <div class="p-d-flex p-jc-between me-2 searchbar-width">
                    <span class="p-input-icon-left text-blue">
                        <i class="pi pi-search" />
                        <InputText v-if="selected_view.value == 2" class="tableSearch "  v-model="vm.filter_list['name'].value" placeholder="Search..." />
                        <InputText v-else class="tableSearch" v-model="search" placeholder="Search..." />

                    </span>
                </div>
                <!-- 
                <a href="Javascript:void(0)" @click="refreshList" class="ml-2 btn btn-sm btn-light-primary fw-600 me-2">
                    <i class="pi pi-search  search-icon" />
                </a> -->
            </h3>
        </div>
        <div class="card card-custom" v-if="selected_view.value == 2">
            <div class="card-body">
               <div class="table-responsive">
					<TeachersTableView :teacher_list="teacher_list" :loading="loading" :filter="vm"></TeachersTableView>
			    </div>
            </div>
        </div>
        <div v-else>
            <div v-if="loading" class="text-center align-items-center mt-5 pt-5">
                <div class="spinner-grow text-info" role="status" style="width: 3rem; height: 3rem;">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="row" v-if="paginated.length > 0 ">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6" v-for="(item, i) in paginated" :key="i">
                    <TeachersCardView :item="item" :index="i"></TeachersCardView>
                </div>
                <div class="col-12">
                        <Paginator 
                        v-if="!loading"
                        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                         currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                         :rows="per_page"  @page="paginate($event, filtered_list)" :totalRecords="filtered_list.length" :rowsPerPageOptions="[6, 10,20,30,50,100,1000]"> </Paginator>
                </div>
            </div>
            <div v-else class="pt-4">
                    <no-results-error v-if="loading == false" :message="`No teacher found!`"></no-results-error>
            </div>
        </div>
            
        </div>
    </div>
<TeachersStudents :student_list_watcher="student_list_watcher" :selected_teacher="selected_teacher"></TeachersStudents>
<TeachersCreateOrEdit :add_teacher_watcher="add_teacher_watcher" :view="view" :selected_teacher="selected_teacher"></TeachersCreateOrEdit>
<ConfirmDialog></ConfirmDialog>

</div>
</template>
<script>
import { defineComponent, onMounted, reactive, inject, ref, computed, watch, provide } from 'vue'
import  TeachersGetList  from '@/composables/teachers/TeachersGetList'
import  TeachersDelete  from '@/composables/teachers/TeachersDelete'
import  usePagination  from '@/composables/common/usePagination'


import {FilterMatchMode} from 'primevue/api'
import InputText from 'primevue/inputtext'
import TeachersCreateOrEdit from './TeachersCreateOrEdit.vue'
import TeachersStudents from './TeachersStudents.vue'
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from "primevue/useconfirm";
import SelectButton from 'primevue/selectbutton';
import VPagination from "@hennge/vue3-pagination";
import NoResultsError from '@/components/NoResultsError.vue'
import TeachersTableView from './TeachersTableView.vue'
import TeachersCardView from './TeachersCardView.vue'
import Paginator from 'primevue/paginator';
import  TeachersEmailCredentials  from '@/composables/teachers/TeachersEmailCredentials'

export default defineComponent({
    components: { Paginator, TeachersCardView, TeachersTableView, NoResultsError, SelectButton, InputText, TeachersCreateOrEdit, TeachersStudents, ConfirmDialog },
    setup() {
        const confirm = useConfirm();
		const { toast, constants, http, store } = inject('App')
        const { send_status, sendTeacherCredentials } = TeachersEmailCredentials(http, constants, toast)
        const  { loading, teacher_list, getTeacherList } = TeachersGetList(http, constants, store)
        const { delete_status, deleteTeacher } = TeachersDelete(http, constants, toast)
        const { activePage, setActivePage, paginated, paginate } = usePagination()


        
        const vm = reactive({filter_list: { 'name': {value: null, matchMode: FilterMatchMode.CONTAINS} }})
        let view = ref('list')
        const selected_teacher = ref([])
        const add_teacher_watcher = ref(1)
        const student_list_watcher = ref(1)
        const selected_view = ref({icon: 'pi pi-th-large', value: 1})

        const view_options = ref([
            {icon: 'pi pi-th-large', value: 1},
            {icon: 'pi pi-table', value: 2},
        ]);
        const page = ref(1)
        const per_page = ref(6)
        const current_page = ref({
            first: 1,
            page: activePage.value,
            pageCount: 6,
            rows: per_page.value
        })
        const search = ref('')

       


        function addTeacher() {
            add_teacher_watcher.value++
            store.commit('TeachersModule/ADD_TEACHER', 'add')
        }
        function edit(s) {
            selected_teacher.value = s
            add_teacher_watcher.value++
            store.commit('TeachersModule/ADD_TEACHER', 'edit')
        }
        function deletePrompt(s) {  
            selected_teacher.value = s
            confirm.require({
                message: `Are you sure you want to delete teacher ${ selected_teacher.value.fname}?`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteNow()
                },
                reject: () => {
                }
            });
            return {deletePrompt};
        } 
        function deleteNow() {
            deleteTeacher(selected_teacher.value.id, selected_teacher.value.user_id)
        }
        function viewStudents(s) {
            selected_teacher.value = s.data
            student_list_watcher.value++
        }
        function handleCommand(c) {
            if (c.action == 'delete')
                deletePrompt(c.data)
            else if (c.action == 'edit')
                edit(c.data)
            else if (c.action == 'credentials')
                sendCredentials(c.data)
        }
        function sendCredentials(d) {
            selected_teacher.value = d
            sendTeacherCredentials(d.fname, d.email, d.password)
        }
        function onPage(page) {
            console.log(page)
        }
        function refreshList(arr, size) {
            search.value = ''
            getTeacherList()
        }
        function updateList(teacher) {
            const newArr = teacher_list.value.map(obj => {
            if (obj.id === teacher.id) {
                return {...obj,
                    class_id: teacher.class_id,
                    class_list: teacher.class_list,
                    created_at: teacher.created_at,
                    email: teacher.email,
                    fname: teacher.fname,
                    gender: teacher.gender,
                    id: teacher.id,
                    lname: teacher.lname,
                    name: teacher.name,
                    password: teacher.password,
                    school_id: teacher.school_id,
                    status: teacher.status,
                    studentcount: teacher.studentcount,
                    title: teacher.title,
                    user: teacher.user,
                    user_id: teacher.user_id,
                };
            }

            return obj;
            });
            teacher_list.value = newArr
        }


        const filtered_list = computed(() => {
        return teacher_list.value.filter((obj) => {
            return (
                obj.name
                .toLowerCase()
                .indexOf(search.value.toLowerCase()) != -1
            );
            });
        });
        const pageCount = computed(() => {
            let s = filtered_list.value.length / per_page.value
            return s <= 0 ? 1 : Math.floor(s)
        });

        view = computed(() => store.getters['TeachersModule/GET_VIEW'])

        
        watch(view, (n, o) => {
            if (view.value == 'list')
                getTeacherList()
        });
        watch(search, (n, o) => {
            setActivePage(0)
        });
        watch(filtered_list, (n, o) => { 
             current_page.value = {
                    first: 0,
                    page: activePage.value,
                    rows: current_page.value.rows,
                    pageCount: filtered_list.value.length
            }

           paginate(current_page.value, filtered_list.value)
        });
        watch(delete_status, (n, o) => {
            if (delete_status.value) {
                 let index = teacher_list.value.findIndex(obj => obj.id === selected_teacher.value.id)
                teacher_list.value.splice(index, 1)
            }    
        });
         watch(loading, (n, o) => {
            if (loading.value == false)
                search.value = ''
        });
        onMounted(() => {
            getTeacherList()
        })
        provide('Teachers', { updateList, getTeacherList, handleCommand })
        return {
            view,
            teacher_list, vm, loading,
            selected_teacher,
            deletePrompt,
            edit,
            add_teacher_watcher, addTeacher,
            viewStudents,
            student_list_watcher, getTeacherList,
            web_url: `${process.env.VUE_APP_API_SITE}${constants.USERS_THUMBNAIL_PATH}`,
            handleCommand,
            view_options, selected_view,
            page, per_page,
            paginate, paginated, search, filtered_list, refreshList,
            pageCount, onPage, activePage
        }
    },
})
</script>
