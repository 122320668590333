<template>
      <Sidebar  v-model:visible="display" position="right" class="p-sidebar-md"  :dismissable="false" :modal="true" :showCloseIcon="false" >
        <form  ref="teachersData" @submit.prevent="save" action="post" >    
            <div class="rpanel-title text-capitalize">
                <div class="flex">
                    <div class="w-100">
                        <p>Teacher</p>
                        <small v-if="view == 'add'">Add new Teacher</small>
                        <small v-else>Edit Teacher</small>
                    </div>
                    <span @click="display = false"  class="cursor-pointer fs-20"><i class="pi pi-times"></i></span>
                </div>
            </div>
            <div class="drawer-content">
                <div  class="p-10   pt-0 mt-0">
                    <div class="form-group">
                        <label>First Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control"  name="fname" v-model="fname" placeholder="Enter first name" required/>
                    </div>
                    <div class="form-group">
                        <label>Last Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="lname" v-model="lname" placeholder="Enter last name" required/>
                    </div>
                    <div class="form-group pb-2">
                        <label>Gender <span class="text-danger">*</span></label>
                        <div class="mb-3" v-for="(row, x) in ['Male', 'Female', 'Non-binary']" :key="x">
                                <div class="p-field-radiobutton">
                                <RadioButton id="yesno" name="gender" :value="row" v-model="gender" />
                                <label for="yesno">{{ row }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">    
                        <label class="w-100">Profile photo</label>
                        <div class="row mb-5 pb-5" >
                            <div class="col-sm-12 col-md-12">
                                <RadioButton id="multi" :value="1" v-model="thumbnail_src" />
                                <label for="multi" class="checkbox-label">(Automatic) Generate from random avatars</label>
                            </div>
                            <div class="col-sm-12 col-md-12 pt-4">
                                <RadioButton id="long"  :value="2" v-model="thumbnail_src" />
                                <label for="long" class="checkbox-label">Select</label>
                            </div>
                        </div>
                        <div v-if="thumbnail_src == 1">
                            <div>
                                <p>Update avatar?</p>
                                <InputSwitch v-model="isUpdateAvatar" />
                            </div>
                        </div>
                        <div v-if="thumbnail_src == 2">
                            <el-radio-group v-model="thumbnail_type" style="margin-bottom: 30px">
                                <el-radio-button label="color">Color</el-radio-button>
                                <el-radio-button label="upload"  >Upload</el-radio-button>
                                <el-radio-button label="image"  >Free Images</el-radio-button>
                                <el-radio-button label="library"  >Image Library</el-radio-button>
                            </el-radio-group>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'color'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="box-shadowv4 ">
                                        <el-color-picker v-model="thumbnail_color" />
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'upload'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer" >
                                        <img v-if="teachers_upload_thumb != ''"  :src="`${path}${teachers_upload_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="teachers_upload_thumb == ''"   class="pi pi-cloud-upload icon-download"></i>
                                        <i @click="resetTeachersUploadThumbPreview"  v-if="teachers_upload_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <div class="upload-example__buttons-wrapper flex">
                                        <button  type="button" class="upload-example__button  mt-3" @click="$refs.file.click()">
                                            <input ref="file" type="file" accept="image/*" @change="loadImage($event)" />Select Image
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'library'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer">
                                        <img v-if="teachers_library_thumb != ''"  @click="showLibrary" :src="`${path}${teachers_library_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="teachers_library_thumb == ''"  @click="showLibrary" class="pi pi-images icon-download"></i>
                                        <i @click="resetTeachersLibraryThumbPreview"  v-if="teachers_library_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <span>Recommended size is 120x120</span>
                                </div>
                            </div>
                            <div class="row pt-0 mt-0" v-if="thumbnail_type == 'image'">
                                <div class="col-12 pt-0 mt-0">
                                    <div class="thumb_preview cursor-pointer">
                                        <img v-if="teachers_thumb != ''"  @click="uploadEvent" :src="`${path}${teachers_thumb}`" style="width: 200px; height: 198px">
                                        <i  v-if="teachers_thumb == ''"  @click="uploadEvent" class="pi pi-images icon-download"></i>
                                        <i @click="resetPreview"  v-if="teachers_thumb != ''" class="pi pi-times icon-close"></i>
                                    </div>
                                    <span>Recommended size is 120x120</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control" name="title" v-model="teacher_title" placeholder="Enter teacher title" required/>
                    </div>
                    <div class="form-group">
                        <label>Email Address <span class="text-danger">*</span></label>
                        <input type="email" class="form-control" name="email" v-model="email" placeholder="Enter email address" required/>
                    </div>
                    <div class="check-switch-main pt-2 mt-3 p-t-5 pb-5">
                        <div>
                            <p>Notify teacher with credentials?</p>
                            <InputSwitch v-model="isNotify" />
                        </div>
                    </div>

                    <div class="form-group" v-show="isNotify">
                        <label>Password <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" name="password" v-model="password" placeholder="Enter password" />
                        <p class="pt-2" v-if="password != ''">Password will be emailed to <a href="Javascript:void(0)">{{email}} </a></p>
                    </div>
                </div>
            </div>
            <div class="r-panel-footer drawer-footer--v2">
                <div class="r-panel-footer-content">
                    <button type="button" :disabled="create_status > 0" @click="display = false" class="cursor-pointer btn btn-sm btn-secondary om-add-contact-toggle mr-2">Cancel</button>
                    <button type="submit" :disabled="create_status > 0" class="btn btn-sm btn-info save mr-2">
                        <span  v-if="create_status <= 0">  Save</span>
                        <span v-else><i class="fas fa-spinner fa-spinner"></i> Saving...</span>
                    </button>
                </div>
            </div>
        </form>
      </Sidebar>
      <ImageLibrary :path="`thumbnail/users`" :modules="`UserModule/SET_USER_LIBRARY_THUMB`" :thumbnail_type="`thumbnail`" :image_library_watcher="image_library_watcher" :type="`user_library_thumb`"></ImageLibrary>
      <PexelsImages  :modules="`UserModule/SET_USER_THUMB`" :pexels_image_watcher="pexels_image_watcher"  :source='`user_thumb`'></PexelsImages>
      <ImageCropper :modules="`UserModule/SET_USER_UPLOAD_THUMB`" :ratioH="120" :ratioW="120" :selected_image="teacher_upload_thumb_set.src" :image_type="`blob`" :source="`user_upload_thumb`" :image_cropper_watcher="image_cropper_watcher"></ImageCropper>

</template>
<script>
import  SchoolsGetList  from '@/composables/schools/SchoolsGetList'
import  TeachersAdd  from '@/composables/teachers/TeachersAdd'

import { defineComponent, ref, inject, watch, computed, onMounted, toRef, provide, reactive } from 'vue'
import { useToast } from "primevue/usetoast";
import { useStore  } from 'vuex'
import Dialog from 'primevue/dialog';
import Sidebar from 'primevue/sidebar';
import PexelsImages from '@/components/PexelsImages.vue'
import { ElRadioGroup, ElRadioButton, ElColorPicker }   from  'element-plus'
import ColorPicker from 'primevue/colorpicker';
import RadioButton from 'primevue/radiobutton';
import ImageCropper from '@/components/ImageCropper.vue';
import ImageLibrary from '@/components/ImageLibrary.vue';
import InputSwitch from 'primevue/inputswitch'

export default defineComponent({
    components: { ElColorPicker, InputSwitch, Sidebar, RadioButton, ElRadioGroup, ElRadioButton, PexelsImages, ImageCropper, ImageLibrary },
    props: {
        view: {
            type: String,
            default: 'create' 
        },
        selected_teacher: {
            default: []
        },
        add_teacher_watcher: {
            default: 1,
            type: Number
        }
    },
    setup(props) {
		const { utils, toast, constants, http, store } = inject('App')

        const title = ref('Add Teacher')        
        const selected_school = ref()
        const fname = ref('')
        const lname = ref('') 
        const email = ref('')
        let schools = ref([])
        const password = ref('')
        const teachersData =  ref('')
        const display = ref(false)
        const teacher_title = ref('')
        
        const { updateList, getTeacherList } = inject('Teachers')
        const { loading, school_list, getSchoolsList } = SchoolsGetList(http, constants, store)
        const { createResponse, create_status, addTeacher } = TeachersAdd(http, constants, toast)

        const thumbnail_type = ref('color')
        const pexels_image_watcher = ref(6)
        const thumbnail_color = ref('893BF7')
        const thumbnail_src = ref(1)

        let teacher_upload_thumb_set = reactive({
				src: null,
				type: null
        })
        const image_cropper_watcher = ref(1)
        const image_library_watcher = ref(1)
        const isNotify = ref(false)
        const gender = ref('Male')
        const isUpdateAvatar = ref(false)


        schools = computed(() => store.getters['SchoolsModule/GET_SCHOOL_LIST'])
        let teachers_thumb =  computed(() => store.getters['UserModule/GET_USER_THUMB'])
        let teachers_upload_thumb =  computed(() => store.getters['UserModule/GET_USER_UPLOAD_THUMB'])
        let teachers_library_thumb =  computed(() => store.getters['UserModule/GET_USER_LIBRARY_THUMB'])
        let path =  computed(() => store.getters['UserModule/GET_PATH'])


        function save() {
            let image  = ''
            if (thumbnail_type.value == 'upload')
                image = teachers_upload_thumb.value
            else if (thumbnail_type.value == 'library')
                image = teachers_library_thumb.value
            else
                image = teachers_thumb.value

            addTeacher(props.selected_teacher.id,  props.selected_teacher.user_id,  props.view, teachersData.value,
             thumbnail_type.value, removeHex(thumbnail_color.value), image, thumbnail_src.value, isNotify.value, isUpdateAvatar.value)
        }
        function removeHex(str) {
            return str = str.substring(1);
        }
        function uploadEvent() {
          pexels_image_watcher.value++
        }
        function resetPreview() {
            if (props.view == 'add')
              store.commit('UserModule/SET_USER_THUMB', '')
            else 
              store.commit('UserModule/SET_USER_THUMB', props.selected_teacher.user.thumbnail)
        }
        function resetTeachersUploadThumbPreview() {
            if (props.view == 'add')
              store.commit('UserModule/SET_USER_UPLOAD_THUMB', '')
            else 
              store.commit('UserModule/SET_USER_UPLOAD_THUMB', props.selected_teacher.user.thumbnail)
        }
        function resetTeachersLibraryThumbPreview() {
            if (props.view == 'add')
              store.commit('UserModule/SET_USER_LIBRARY_THUMB', '')
            else 
              store.commit('UserModule/SET_USER_LIBRARY_THUMB', props.selected_teacher.user.thumbnail)
        }
        function loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (teacher_upload_thumb_set.src) {
					URL.revokeObjectURL(teacher_upload_thumb_set.src)
				}
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    teacher_upload_thumb_set.src = blob
                    teacher_upload_thumb_set.type =  utils.getMimeType(e.target.result, files[0].type)
                    image_cropper_watcher.value++
                    
				};
				reader.readAsArrayBuffer(files[0]);
			}
		}
        function showLibrary() {
            image_library_watcher.value++
        }
        function closeDialog(){}
        
        watch(create_status, (n, o) => {
            if (create_status.value == 0)  {
                console.log(createResponse)
                if (props.view == 'add')
                    getTeacherList()
                else 
                    updateList(createResponse.data)

                display.value = false
            }
        });

        watch(()=>props.add_teacher_watcher, (n) => {
            display.value = true
            title.value = 'Add Teacher'
            isUpdateAvatar.value = false
            if (props.view == 'edit') {
                title.value = `Edit teacher`
                fname.value = props.selected_teacher.fname
                lname.value = props.selected_teacher.lname
                email.value = props.selected_teacher.email
                password.value = props.selected_teacher.password
                teacher_title.value = props.selected_teacher.title

                store.commit('UserModule/SET_USER_THUMB', props.selected_teacher.user)
                store.commit('UserModule/SET_USER_UPLOAD_THUMB', props.selected_teacher.user)
                store.commit('UserModule/SET_USER_LIBRARY_THUMB', props.selected_teacher.user)

                thumbnail_type.value = props.selected_teacher.user.thumbnail_type
                thumbnail_color.value = `#${props.selected_teacher.user.thumbnail_bg}`
                thumbnail_src.value = props.selected_teacher.user.thumbnail_src
                gender.value = props.selected_teacher.gender

                if (gender.value == null)
                    gender.value = 'Male'

            } else {
                title.value = ''
                fname.value = ''
                lname.value = ''
                email.value = ''
                password.value = utils.generatePassword()
                teacher_title.value = '',
                gender.value  = 'Female'


                store.commit('UserModule/SET_USER_THUMB', '')
                store.commit('UserModule/SET_USER_UPLOAD_THUMB', '')
                store.commit('UserModule/SET_USER_LIBRARY_THUMB', '')
                store.commit('UserModule/SET_PATH', process.env.VUE_APP_AWS_URL)


                thumbnail_type.value = 'color'
                thumbnail_color.value = '#893BF7'
                thumbnail_src.value = 1
            }
            if (schools.value.length == 0)
                  getSchoolsList()

        })

        onMounted(() => {
        })
        provide('CropperParent', { closeDialog })

        return {
            title,
            school_list,
            schools,
            selected_school, 
            fname, lname, email, password,
            teachersData, gender,
            uploadEvent, resetPreview,
            teachers_upload_thumb,
            resetTeachersUploadThumbPreview, loadImage,
            showLibrary, image_library_watcher, isNotify,
            teacher_upload_thumb_set, image_cropper_watcher, teachers_library_thumb, resetTeachersLibraryThumbPreview,
            save, display, create_status, teacher_title, teachers_thumb,  thumbnail_type, thumbnail_color, thumbnail_src, pexels_image_watcher,
            isUpdateAvatar, path
        } 
    },
    
})
</script>