import { ref } from 'vue'
export default function TeachersEmailCredentials(http, constant, toast) {
    const send_status = ref(0)
    async function sendTeacherCredentials(fname, email, password) {
        try {
            send_status.value = 1

            const f = new URLSearchParams()
            f.append('fname', fname)
            f.append('email', email)
            f.append('password', password)
            
            const response = await http.post(constant.TEACHERS_EMAIL_CREDENTIALS, f)
            send_status.value = 0
            if (response.data.status > 0)
                toast.add({severity:'success', summary: 'Success', detail:'Teacher  credentials was successfully sent.', life: 3000});
            else {
                send_status.value = -1
                toast.add({severity:'error', summary: 'Success', detail:'Unable to send credentials, Please try again.', life: 3000});
            }
        } catch (err) {
            send_status.value = 0
        }
    }
    return {
        send_status,
        sendTeacherCredentials
    }
}